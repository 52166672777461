import { PATH_APP } from '../paths';

const scan = {
  path: PATH_APP.data.scans,
  index: 'scans',
  title: 'Scans',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Scans' }],
  queryBy: 'pass,trip,location',
  sortBy: 'updated',
  defaultOrder: 'desc',
  customFilters: [
    {
      id: 'date',
      type: 'date',
      placeholder: 'Scan date',
      key: 'updated'
    }
  ],
  data: [
    {
      column: {
        id: 'pass',
        label: 'Pass',
        alignRight: false
      },
      key: 'pass'
    },
    {
      column: {
        id: 'trip',
        label: 'Trip',
        alignRight: false
      },
      key: 'trip'
    },
    {
      column: {
        id: 'location',
        label: 'Location',
        alignRight: false
      },
      key: 'location'
    },
    {
      column: {
        id: 'updated',
        label: 'Date/Time',
        alignRight: false
      },
      key: 'updated',
      format: 'date'
    }
  ],
  plural: 'scans',
  singular: 'scan'
};

export default scan;
