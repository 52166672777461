import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect, useLocation } from 'react-router-dom';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, isUnassigned, user } = useAuth();
  const { pathname } = useLocation();

  // if (
  //   isLoading ||
  //   !user.organization ||
  //   !user.organization.id ||
  //   !user.organization.name
  // ) {
  //   return <LoadingScreen />;
  // }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname === PATH_PAGE.loginLink) {
      return <Redirect to={PATH_PAGE.loginLink} />;
    } else {
      return <Redirect to={PATH_PAGE.login} />;
    }
  }

  if (isUnassigned && pathname !== PATH_APP.unassigned) {
    return <Redirect to={PATH_APP.unassigned} />;
  }

  return <>{children}</>;
}

export default AuthProtect;
