import { PATH_APP } from '../paths';

const allocation = {
  allocation: true,
  path: PATH_APP.trips.allocations,
  index: 'trips',
  title: 'Allocations',
  defaultFilter: 'start >= startOfToday',
  // alternativeFilter: 'start >= endOfToday',
  // alternativeIndex: 'trips_desc',
  sortBy: 'start',
  defaultOrder: 'asc',
  rowOptions: { multiple: true },
  breadcrumb: [
    { name: 'Portal', href: PATH_APP.root },
    { name: 'Trips', href: PATH_APP.trips.list },
    { name: 'Allocations' }
  ],
  customFilters: [
    {
      id: 'date',
      type: 'date',
      placeholder: 'Trip date',
      key: 'start'
    },
    {
      id: 'driverAllocated',
      lower: true,
      type: 'select',
      placeholder: 'Device',
      key: 'driverAllocated',
      minWidth: 200,
      values: ['Allocated', 'Unallocated']
    },
    // {
    //   id: 'driver',
    //   lower: true,
    //   type: 'select',
    //   placeholder: 'Driver',
    //   key: 'driverAllocated',
    //   minWidth: 200,
    //   values: ['Allocated', 'Unallocated']
    // },
    // {
    //   id: 'vehicleAllocated',
    //   lower: true,
    //   type: 'select',
    //   placeholder: 'Vehicle',
    //   key: 'vehicleAllocated',
    //   minWidth: 200,
    //   values: ['Allocated', 'Unallocated']
    // },
    {
      id: 'board',
      type: 'board',
      placeholder: 'Board',
      key: 'board'
    }
  ],
  data: [
    {
      column: {
        id: 'publicId',
        label: 'ID',
        alignRight: false,
        minWidth: 90
      },
      key: 'publicId'
    },
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false,
        width: 300
      },
      key: 'name',
      link: true
    },
    {
      column: {
        id: 'board',
        label: 'Board',
        alignRight: false,
        width: 300
      },
      key: 'board',
      link: true
    },
    {
      column: {
        id: 'sequence',
        label: 'Seq.',
        alignRight: false,
        width: 300
      },
      key: 'sequence',
      link: true
    },
    {
      column: {
        id: 'start',
        label: 'Departure',
        alignRight: false
      },
      format: 'date',
      key: 'start',
      asc: 'trips_start_asc',
      desc: 'trips_start_desc'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    },
    {
      column: {
        id: 'scheduledDriver',
        label: 'Device',
        alignRight: false
      },
      key: 'scheduledDriver'
    },
    // {
    //   column: {
    //     id: 'driver',
    //     label: 'Driver',
    //     alignRight: false
    //   },
    //   key: 'scheduledAllocation.driver.name'
    // },
    // {
    //   column: {
    //     id: 'vehicle',
    //     label: 'Vehicle',
    //     alignRight: true
    //   },
    //   key: 'scheduledAllocation.vehicle.name'
    // },
    {
      column: {
        id: 'scheduledUpdated',
        label: 'Last Updated',
        alignRight: false
      },
      format: 'date',
      key: 'scheduledUpdated'
    },
    {
      column: {
        id: 'allocate',
        label: '',
        alignRight: false
      },
      component: {
        name: 'allocate'
      },
      key: 'allocate'
    }
  ],
  plural: 'trips',
  singular: 'trip',
  queryBy: 'publicId,name,board,sequence,operator,device'
};

export default allocation;
