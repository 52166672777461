import { PATH_APP } from '../paths';

const route = {
  customFilters: [
    {
      id: 'organizations',
      type: 'operators',
      placeholder: 'Operators',
      key: 'organizations.id'
    }
  ],
  path: PATH_APP.organization.routes,
  index: 'routes',
  title: 'Routes',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Routes' }],
  collection: 'routes',
  inputs: [
    {
      id: 'line',
      label: 'Line',
      sm: 12
    },
    {
      id: 'description',
      label: 'Description',
      sm: 12
    }
  ],
  rowOptions: {
    edit: true
  },
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'description',
        label: 'Description',
        alignRight: false
      },
      key: 'description'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    }
  ],
  plural: 'routes',
  singular: 'route',
  queryBy: 'name,description,operator'
};

export default route;
