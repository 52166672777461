import { PATH_APP } from '../paths';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const driver = {
  customFilters: [
    {
      id: 'organizations',
      type: 'operators',
      placeholder: 'Operators',
      key: 'organizations.id'
    }
  ],
  permissions: {
    create: true,
    delete: true,
    edit: true
  },
  path: PATH_APP.organization.drivers,
  collection: 'drivers',
  index: 'drivers',
  queryBy: 'name,phone',
  title: 'Drivers',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Drivers' }],
  deleteDescription:
    'This will delete the driver from your organisation. The driver will no longer be able to sign in using the RYGO Driver app.',
  submitCloudFunction: 'registerDriver',
  dialogSchema: Yup.object().shape({
    forename: Yup.string()
      .required('Forename is required')
      .test(
        'isnt-short',
        'Forename too short',
        (value) => value && value.length > 1
      ),
    phone: Yup.string()
      .required('Phone is required')
      .test(
        'is-valid',
        'Invalid phone number',
        (value) => value && isValidPhoneNumber(value)
      )
  }),
  snackbar: {
    title: 'New driver registered'
  },
  inputs: [
    {
      id: 'forename',
      label: 'Forename',
      sm: 6
    },
    {
      id: 'surname',
      label: 'Surname',
      sm: 6
    },
    {
      id: 'nfcId',
      label: 'Nfc Id',
      sm: 12,
      type: 'validator'
    },
    {
      id: 'pin',
      label: 'PIN',
      sm: 12,
      type: 'validator'
    },
    {
      id: 'organizations',
      label: 'Organization',
      type: 'organization',
      default: {}
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'phone'
    },
    {
      id: 'sms',
      label: 'Send SMS',
      type: 'checkbox',
      default: true,
      footer: true,
      edit: false
    },
    {
      id: 'message',
      label: 'SMS Message',
      type: 'textarea',
      condition: 'sms',
      edit: false
    }
  ],
  rowOptions: {
    create: true,
    edit: true,
    delete: true
  },
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'phone',
        label: 'Phone',
        alignRight: false
      },
      key: 'phone'
    },
    {
      column: {
        id: 'pin',
        label: 'PIN',
        alignRight: false
      },
      key: 'pin'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    },
    {
      column: {
        id: 'nfcId',
        label: 'Nfc Id',
        alignRight: false
      },
      key: 'nfcId'
    }
  ],
  plural: 'drivers',
  singular: 'driver'
};

export default driver;
