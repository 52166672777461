import { PATH_APP } from '../paths';

const geofence = {
  path: PATH_APP.data.geofences,
  index: 'geofences',
  title: 'Geofences',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Geofences' }],
  sortBy: 'arrival',
  defaultOrder: 'asc',
  customFilters: [
    {
      id: 'date',
      type: 'date',
      placeholder: 'Geofence arrival date',
      key: 'arrival',
      minWidth: 275
    }
  ],
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'arrival',
        label: 'Arrival',
        alignRight: false
      },
      key: 'arrival',
      format: 'date'
    },
    {
      column: {
        id: 'departure',
        label: 'Departure',
        alignRight: false
      },
      key: 'departure',
      format: 'date'
    },
    {
      column: {
        id: 'trip',
        label: 'Trip',
        alignRight: false
      },
      component: {
        data: {
          icon: 'route'
        },
        name: 'chip'
      },
      key: 'trip'
    }
  ],
  plural: 'geofences',
  singular: 'geofence',
  queryBy: 'name,trip'
};

export default geofence;
