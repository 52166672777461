import NProgress from 'nprogress';
import PortalRoutes from './PortalRoutes';
import { PATH_APP, PATH_PAGE } from './paths';
import HomeRoutes from './HomeRoutes';
import DocsRoutes from './DocsRoutes';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestProtect from 'src/components/Auth/GuestProtect';
import AuthProtect from 'src/components/Auth/AuthProtect';
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const templateProps = route.props || {};

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...templateProps} {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  process.env.REACT_APP_SITE === 'portal' && {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.login,
    component: lazy(() => import('src/views/auth/LoginPasswordView'))
  },
  process.env.REACT_APP_SITE === 'portal' && {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.loginLink,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  process.env.REACT_APP_SITE === 'portal' && {
    exact: true,
    path: PATH_PAGE.loginUnprotected,
    component: lazy(() => import('src/views/auth/LoginPasswordView'))
  },
  process.env.REACT_APP_SITE === 'portal' && {
    exact: true,
    path: PATH_PAGE.resetPassword,
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  process.env.REACT_APP_SITE === 'app' && HomeRoutes,
  process.env.REACT_APP_SITE === 'portal' && PortalRoutes
].filter((route) => route);

export default routes;
